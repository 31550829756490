/*eslint-disable*/

$.fn.countBind = function (options) {
  const settings = $.extend(true, {
    prefix: '',
    startQuantity: 0,
    finalQuantity: 999999,
    callback: $.noop
  }, options);

  this.each(function () {
    const $block = $(this);
    const iterator = $block.data('iterator') || 1;
    const $input = $block.find('input');
    const multiplyStatus = $input.attr('data-multiply');
    let oldValue = parseInt($input.val(), 10);



    const disabledMinus = () => {
      if ($input.val() === '1') {
        $block.find('.minus').addClass('is-disabled');
      } else {
        $block.find('.minus').removeClass('is-disabled');
      }
    };

    disabledMinus();

    if (typeof settings.startQuantity === 'undefined') {
      settings.startQuantity = iterator;
    }

    $input.unbind('keyup, keypress');
    // Проверка на ввод цифр
    $input.on('keypress keyup', function (e) {
      if (e.which !== 8 && e.which !== 0 && e.which !== 46 && (e.which < 48 || e.which > 57) || (e.which === 46 && $(this).val().indexOf('.') !== -1)) {
        return false;
      }

      settings.callback($input, this.value);
    });

    // Минус
    $block.find('.minus').off('click.minus').on('click.minus', function () {
      minus();
      disabledMinus();
      return false;
    });

    // Плюс
    $block.find('.plus').off('click.plus').on('click.plus', function () {
      plus();
      disabledMinus();
      return false;
    });

    $block.data('api', {minus, plus});

    function minus() {
      const currentValue = parseInt($input.val(), 10);
      let newValue = currentValue - iterator;

      if ((currentValue <= settings.startQuantity) || (isNaN(currentValue)) || (currentValue === undefined)) {
        newValue = currentValue;
      }
      if (newValue > settings.finalQuantity) {
        newValue = settings.finalQuantity;
      }
      $input.val(newValue + settings.prefix).attr('value', newValue + settings.prefix);

      if (oldValue !== newValue) {
        settings.callback($input, newValue);
      }

      oldValue = newValue;

      $input.trigger('change');

      return newValue;
    }

    function plus() {
      const currentValue = parseInt($input.val(), 10);
      let newValue = currentValue + iterator;

      if ((isNaN(currentValue)) || (currentValue === undefined)) {
        newValue = iterator;
      }
      if (newValue > settings.finalQuantity) {
        newValue = settings.finalQuantity;
      }

      $input.val(newValue + settings.prefix).attr('value', newValue + settings.prefix);

      if (oldValue !== newValue) {
        settings.callback($input, newValue);
      }

      oldValue = newValue;


      $input.trigger('change');

      return newValue;
    }
  });
};
