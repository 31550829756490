/*eslint-disable*/

import '@fancyapps/fancybox';
import Swiper from 'swiper/dist/js/swiper.min';
import 'svgxuse';
import './components/jquery.countBind'
import $ from 'jquery';

window.$ = $;
window.jQuery = $;

(function ($) {
  $(function () {

    $('.gallerySlider').each(function () {
      this.swiper = new Swiper($(this).find('.swiper-container'), {
        slidesPerView: 'auto',
        freeMode: true,
        freeModeSticky: true,
        spaceBetween: 21,
        loop: true,
        speed: 300,
        autoplay: {
          duration: 0,
          disableOnInteraction: false,
          waitForTransition: false,
          reverseDirection: $(this).index()
        }
      });
    });

    $('[data-fancybox]').fancybox({
      touch: false,
      autoFocus: false
    })

    $('.js-toggle-winBox').on('click', function (e) {
      e.preventDefault();


      $(this).parent().addClass('is-hidden');
      $('.winBox__hidden').addClass('is-active');
    });

    $('.btn--burger').on('click', function (e) {
      e.preventDefault();
      $(this).toggleClass('is-active');
    });

    $('.btn--burger a').on('click', function (e) {
      e.stopPropagation();
    });

    $('.goodItem').each(function () {


      $(this).find('.quantity').countBind({
        startQuantity: 1
      });

    });


    $('.card--good').each(function () {
      const $self = $(this);

      this.swiper = new Swiper($self.find('.swiper-container'), {
        speed: 600,
        touchRatio: 0,
        navigation: {
          nextEl: $self.find('.swiper-button-next'),
          prevEl: $self.find('.swiper-button-prev')
        },
        pagination: {
          el: $self.find('.swiper-pagination'),
          type: 'fraction',
          renderFraction(currentClass, totalClass) {
            return `<span class="${currentClass}"></span>` +
              `  из  ` +
              `<span class="${totalClass}"></span>`;
          }
        }
      })

    });

  });
})(jQuery);
